import React, { useEffect, useState } from 'react';
import { useStateContext } from '../contexts/ContextProvider';
import { ColumnDirective, ColumnsDirective, GridComponent, headerContent } from '@syncfusion/ej2-react-grids';
import axios from 'axios';
import { Tooltip } from 'antd';
import { MdOutlineDeleteOutline } from 'react-icons/md';
import { toast } from 'react-toastify';

function FileUploader({ onUpload, accept, heading }) {
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const { currentColor } = useStateContext();
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileName, setFileName] = useState('');
    const [isUploadButtonEnabled, setIsUploadButtonEnabled] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [fileSizeError, setFileSizeError] = useState(null);
    const [files, setFiles] = useState([]);

    const showToast = (toastMsg) => {
        toast(toastMsg, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }
    

    const fetchJarFiles = () => {
        axios.get(`${API_ENDPOINT}/api/use-case/jar`)
            .then((response) => setFiles(response.data.data))
            .catch((error) => console.error('Error fetching data:', error));
    }

    useEffect(() => {
        fetchJarFiles();
    }, [])


    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const fileSize = file.size / (1024 * 1024); // convert to MB

        if (fileSize > 100) {
            setFileSizeError('The maximum allowed size for a JAR file is 10 MB.');
            setIsUploadButtonEnabled(false);
        } else {
            setSelectedFile(file);
            setFileName(`Selected file: ${file.name}`);
            setIsUploadButtonEnabled(true);
            setFileSizeError(null);
        }
    };

    const handleUpload = () => {
        // Implement your file upload logic here
        // This example demonstrates using FormData for potential server-side interactions
        if (onUpload) {
            onUpload(selectedFile); // Pass the selected file to the onUpload handler
        } else {
            // Default upload logic using FormData (same as before)
            const formData = new FormData();
            formData.append('file', selectedFile);

        }

        // Send the FormData to your server-side endpoint using fetch or Axios
        // fetch('/upload', {
        //     method: 'POST',
        //     body: formData,
        // })
        //     .then((response) => response.json())
        //     .then((data) => {
        //         alert(`File uploaded successfully! ${data.message || ''}`); // Handle response from server
        //         setSelectedFile(null);
        //         setFileName('');
        //         setIsUploadButtonEnabled(false);
        //     })
        //     .catch((error) => {
        //         console.error('Error uploading file:', error);
        //         alert('File upload failed. Please try again.');
        //     });
    };

    const handleDeleteFile = (props) => {
        console.log("delete", props);
        if (window.confirm(`Are you sure you want to delete the ${props.name} JAR?`)) {
            axios.delete(`${API_ENDPOINT}/api/use-case/jar/${props.id}`)
                .then((response) => {
                    showToast("Successfully deleted JAR!");
                    fetchJarFiles();
                })
                .catch((error) => {
                    console.error(error);
                    showToast(error.response.data.message);
                });
        }
    }


    const headingContent = heading || "Upload File";

    return (
        <>
        <div className="font-bold">{headingContent}</div>
            <div>
                <input
                    type="file"
                    id="myFile"
                    name="filename"
                    accept={accept}
                    onChange={handleFileChange}
                />
                <br />
                <br />
                <p id="fileName">{fileName}</p>
                {fileSizeError && <p style={{ color: 'red' }}>{fileSizeError}</p>}

                <button
                    style={{ backgroundColor: currentColor }}
                    className=" text-white font-semibold py-2 px-4 rounded mt-4 mx-4 my-4"
                    id="uploadBtn" disabled={!isUploadButtonEnabled} onClick={handleUpload}>
                    Upload
                </button>
            </div>


            <GridComponent
                dataSource={files}
                allowPaging
                pageSettings={{ pageSize: 10 }}
                allowSorting
                toolbar={['Search']}
                width='auto'
            >
                <ColumnsDirective>
                    <ColumnDirective field='name' headerText='Name' width='auto' />
                    <ColumnDirective field='uploadedAt' headerText='Uploaded At' width='auto' />
                    <ColumnDirective field='size' headerText='Size' width='auto' />


                    <ColumnDirective headerText="Actions" width="25%" template={(props) => (
                            <span>
                                <Tooltip title='Delete'>
                                    <button
                                        className="bg-red-500 text-white p-2 rounded hover:bg-red-600 focus:outline-none mr-2"
                                        // title='Delete'
                                        onClick={() => handleDeleteFile(props)}
                                    >
                                        <MdOutlineDeleteOutline></MdOutlineDeleteOutline>
                                    </button>
                                </Tooltip>
                            </span>
                        )}></ColumnDirective>
                </ColumnsDirective>
            </GridComponent>
        
        </>
    );
}

export default FileUploader;
